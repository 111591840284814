import Rails from '@rails/ujs'
import * as Turbo from '@hotwired/turbo-rails'
require('channels')

Rails.start()
window.Turbo = Turbo

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import debounced from 'debounced'
debounced.initialize()

// import 'lightgallery.js/dist/css/lightgallery.min.css'
import 'swiper/swiper-bundle.min.css'

import '../styles/app.css'

import 'controllers'

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register('/service-worker.js', {
      scope: './'
    })
    .then(() => {})
}
